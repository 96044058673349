<template>
  <div class="merch-box">
    <div class="title">
      修改信息<span class="hint" v-if="!$route.query.steured">
        <!-- （*温馨提示：当前信息为商品关键属性，请仔细核对确保无误） -->
      </span>
    </div>
    <div class="modification">
      <div>
        平台手续费比例（修改前）<div >{{ form2.saleSetting.platformHandlingRatio }}%</div>
      </div>
      <div>
        平台手续费比例（修改后）<div class="later">{{ form1.platformHandlingRatio }}%</div>
      </div>
      <div>
        修改原因<div >{{ form1.modification }}</div>
      </div>
    </div>
    <!-- <el-form label-width="150px">
      <el-form-item label="平台手续费比例（修改前）">{{ form2.saleSetting }}
        <div class="input-box" v-if="form2.saleSetting">
          <el-input
            v-model="form2.saleSetting.platformHandlingRatio"
            disabled
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="新平台手续费比例">
        <div class="input-box">
          <el-input v-model="form1.platformHandlingRatio" disabled></el-input>
        </div>
      </el-form-item>
      <el-form-item label="修改原因">
        <div class="input-box">
          <el-input
            v-model="form1.modification"
            type="textarea"
            disabled
          ></el-input>
        </div>
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
export default {
  props: {
    form1: {
      type: Object,
      default: () => {
        return {};
      },
    }, //标签信息
    form2: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
};
</script>

<style lang="scss" scoped>
.merch-box{
  padding: 10px;
}
.input-box {
  width: 400px;
  background: 50px;
}
.title {
  width: 100%;
  font-weight: bold;
  padding-bottom: 16px;
  border-bottom: dashed 1px #ddd;
  display: flex;
  align-items: center;
}
.title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.center-box {
  padding-left: 20px;
  margin-top: 10px;
  width: 100%;
  // border: 1px solid #797979;
  border-radius: 4px;
}
.modification {
  display: flex;
  padding: 30px 0;
  font-size: 14px;
  color: #666;
  width: 100%;
  > div {
    flex: 1;
    // width: 300px;
    display: flex;
    align-items: center;
    div{
      // margin-left: 50px;
      width: 100px;
      padding-left: 50px;
      flex: 1;
      color: #333;
    }
    .later{
      color: #06b7ae;
    }
  }
}
</style>
